import { Injectable } from '@angular/core';
import { IDecodedToken } from '@core/models/auth/IDecodedToken.interface';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

    /**
   * ****************************************************
   * Auth Tokens
   * ****************************************************
   */

  getAuthToken() {
    return localStorage.getItem('FinwaveAuthToken') || '';
  }

  getRefreshToken() {
    return localStorage.getItem('FinwaveRefreshToken') || '';
  }

  setTokens(token: string, refresh_token: string) {
    localStorage.setItem('FinwaveAuthToken', token);
    localStorage.setItem('FinwaveRefreshToken', refresh_token);
  }

  removeTokens() {
    localStorage.removeItem('FinwaveAuthToken');
    localStorage.removeItem('FinwaveRefreshToken');
  }

  decodeToken(token: string): IDecodedToken {
    return JSON.parse(atob(token.split('.')[1]));
    //  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf-8'));
  }

    /**
   * ****************************************************
   * Language preferences
   * ****************************************************
   */

  getLang() {
    return localStorage.getItem('FinwaveLang') || '';
  }

  setLang(lang: string) {
    localStorage.setItem('FinwaveLang', lang);
  }
}
